<template>
    <div class="v-stack h-stretch gap-3">
        <a class="heading-title-2">Settings</a>
        <div class="card light border h-stretch mn" v-if="settings">
            <div class="content h-stretch">
                <form class="v-stack gap-3 h-stretch">
                    <div class="v-stack h-stretch gap-3" v-for="key in Object.keys(settings)" :key="key">
                        <label class="text-left">{{ key }}</label>
                        <input type="number" v-model="settings[key]" />
                    </div>
                    <div class="h-stack h-space-between">
                        <button class="edit" @click.prevent="refresh()">Revert</button>
                        <button class="submit" @click.prevent="setSettings()">Update</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import EventBus from "@/eventbus.js";

export default {
    data() {
        return {
            settings: null
        }
    },
    methods: {
        ...mapActions(["getSettings", "updateSettings"]),
        setSettings() {
            this.updateSettings(this.settings).then(() => {
                this.refresh()
                EventBus.$emit("message", {
                    text: "saved"
                });
            }).catch((error) => {
                console.log(error)
            })
        },
        refresh() {
            this.getSettings().then((settings) => {
                this.settings = settings
                delete this.settings["_id"]
                delete this.settings["__v"]
            }).catch((error) => {
                console.log(error)
            })
        }
    },
    mounted() {
        this.refresh()
    },
}
</script>